import { Box } from 'rebass'
import styled from 'styled-components'

export const InfoCard = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 26px;
  grid-row-gap: 16px;
  .info {
    padding: 16px 16px 26px 16px;
    background-color: ${({ theme }) => theme.bg0};
    border-radius: 16px;
  }

  @media (max-width: 1080px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
